import { Typography } from "@mui/material"
import { Container } from "@mui/system"
import i18next from "i18next"
import * as React from "react"
import Layout from "../components/Layout"
import PageHeading from "../components/PageHeading"
import Seo from "../components/Seo"
import Textblock from "../components/Textblock"
import withTrans from "../components/withTrans"
import theme from "../theme"

const PrivacyPolicyPage = ({ t }) => {
  return (
    <Layout pageName="404" theme={theme}>
      <Container>
        <PageHeading title={t("privacy_policy.heading")} />

        <Textblock mt={theme.spacing(6)}>
          Calories.info is a food database (hereafter: website) which is
          operated by YAZIO GmbH (hereafter: YAZIO, provider) based in Erfurt,
          Germany. The following data protection regulations apply to all users
          of the website. By using the website, you agree to the following data
          protection regulations. For websites of other providers, which are
          referred to e.g. via links, the data protection references and
          explanations there apply.
        </Textblock>

        <Textblock mt={theme.spacing(6)} title="I. Responsible controller">
          Responsible for the collection, processing and use of your personal
          data within the meaning of the Data Protection Regulation (GDPR) is
          <br />
          <br />
          YAZIO GmbH
          <br />
          Kartäuserstraße 13a
          <br />
          99084 Erfurt
          <br />
          Germany
          <br />
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="II. Data protection officer">
          If you have any questions concerning your personal data or this data
          protection declaration or the assertion of your affected party rights,
          you can contact our data protection officer in addition to the
          responsible controller:
          <br />
          <br />
          Dr. Gernot Schmitt-Gaedke
          <br />
          Friedensstraße 11 (Junior-Haus)
          <br />
          60311 Frankfurt/Main
          <br />
          Germany
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="III. Encryption">
          <Typography variant="body1" component="div" gutterBottom>
            <strong>1. Personal data</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            “Personal data” means any information relating to an identified or
            identifiable natural person (“data subject”); an identifiable
            natural person is one who can be identified, directly or indirectly,
            in particular by reference to an identifier such as a name, an
            identification number, location data, an online identifier or to one
            or more factors specific to the physical, physiological, genetic,
            mental, economic, cultural or social identity of that natural
            person.
          </Typography>
          <Typography component="p" gutterBottom>
            The provider only processes your personal data (e.g. gender, date of
            birth, height, weight) in accordance with the provisions of
            applicable data protection law. The following regulations inform you
            about the type, scope and purpose of the collection, processing and
            use of personal data.{" "}
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>2. Data collection when using our website </strong>
          </Typography>
          <Typography component="p" gutterBottom>
            When you visit our website www.calories.info, the web server
            automatically records log files on the basis of our legitimate
            interests in accordance with Art. 6 (1) lit. f GDPR, which cannot be
            assigned to any particular person. This data includes, e.g., browser
            type and version, operating system used, referrer URL (the
            previously visited page), IP address of the requesting computer,
            access date and time of the server request and the file request of
            the client (file name and URL). These data are collected only for
            the purpose of statistical evaluation and for security reasons (e.g.
            to clarify misuse or fraud) and are stored for a period of 7 days
            and then deleted. If the data has to be kept for a longer period of
            time for evidential purposes, it is excluded from deletion until the
            respective incident has been finally clarified.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>3. Use of cookies </strong>
          </Typography>
          <Typography component="p" gutterBottom>
            In order to make our websites overall more user-friendly and
            effective, YAZIO stores so-called cookies on the customer’s hard
            disk, either itself or through third parties commissioned by YAZIO.
            The legal basis is Art. 6 (1) lit. f GDPR. A cookie is a small text
            file that is used to collect information regarding the usage of a
            website. These cookies cannot run programs or deliver viruses to
            your computer. They do not contain any personal data, cannot be
            assigned to specific persons and, unless otherwise described, are
            automatically deleted after one year at the latest. This data is not
            merged with other data sources. The use of our websites is also
            possible without cookies. You can deactivate the storage of cookies
            in your respective browser, restrict it to certain websites or set
            your browser to notify you as soon as a cookie is sent. You can also
            delete cookies from the hard disk of your PC at any time.
          </Typography>
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="V. Use by children">
          YAZIO is aware of the importance of additional measures to protect the
          privacy of children. Persons under the age of 16 are not allowed to
          open accounts unless one parent has agreed to this under applicable
          law. If we learn that we have collected personal information from a
          child under the minimum age without parental consent, we will take
          steps to delete the information immediately. Parents who believe that
          their child has provided us with personal information and would like
          to have it deleted, please contact us using the contact details given
          in item 1.
        </Textblock>
        <Textblock
          mt={theme.spacing(6)}
          title="VI. Use of web analysis, remarketing and retargeting tools"
        >
          Based on our legitimate interests according to Art. 6 (1) lit. f GDPR,
          we use various tools or plug-ins for web analysis, remarketing and
          retargeting to optimize our online presence and to be able to compile
          more targeted offers for you. We use Google Analytics, a web analysis
          service from Google. Google Analytics uses cookies that enable an
          analysis of the use of the website by the users. The information
          generated by the cookie about your use of the website (including your
          IP address) will generally be transmitted to and stored by Google on
          servers in the United States. YAZIO has activated IP anonymization by
          adding the code “gat._anonymizeIp();” to the web pages, so that your
          IP address is previously shortened by Google within EU member states
          or in other contracting states of the Agreement on the European
          Economic Area (“IP masking”). In exceptional cases only, the full IP
          address is transferred to a Google server in the USA and shortened
          there. On our behalf, Google will use this information to evaluate
          your use of the website, to compile reports on website activity for
          YAZIO and to provide other services related to website and internet
          usage. Google may also transfer this information to third parties
          where required by law or insofar as third parties process this data on
          behalf of Google. Google offers an extension for web browsers
          (Add-On), by which the data collection by Google Analytics and the
          processing of this data by Google can be prevented. The add-on can be
          downloaded and installed at your own risk at
          https://tools.google.com/dlpage/gaoptout. For more information
          (general information about Google Analytics and data protection),
          please go to:
          http://www.google.com/intl/de/analytics/privacyoverview.html Google is
          certified under the Privacy Shield Agreement and thus offers a
          guarantee of compliance with European data protection law:
          https://www.privacyshield.gov/participant?id=a2zt000000001L5AAI
        </Textblock>
        <Textblock mt={theme.spacing(6)} title="VII. Your rights">
          <Typography variant="body1" component="div" gutterBottom>
            <strong>1. Right of access (Art. 15 GDPR)</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            You have the right to request free information at any time about
            your personal data stored by YAZIO, its origin and recipients, the
            purpose of the processing, the planned duration of the data storage,
            including a copy of the personal data undergoing processing.
          </Typography>

          <Typography variant="body1" component="div" gutterBottom>
            <strong>2. Right to rectification (Art. 16 GDPR)</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            The data subject shall have the right to obtain from the controller
            without undue delay the rectification of inaccurate personal data
            concerning him or her. Taking into account the purposes of the
            processing, the data subject shall have the right to have incomplete
            personal data completed, including by means of providing a
            supplementary statement.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>3. Right of withdrawal of consent (Art. 7 (3) GDPR)</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            The data subject shall have the right to withdraw his or her consent
            at any time and with effect for the future, without any reason for
            withdrawal.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>
              4. Right to erasure (‘right to be forgotten’) (Art. 17 GDPR)
            </strong>
          </Typography>
          <Typography component="p" gutterBottom>
            Under the conditions of Art. 17 DSGVO, you can request the erasure
            of your personal data. Your right to erasure depends, among other
            things, on whether the data concerning you is still required by us
            to fulfil our lawful duties.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>
              5. Right to restriction of processing (Art. 18 GDPR)
            </strong>
          </Typography>
          <Typography component="p" gutterBottom>
            The data subject shall have the right to obtain from the controller
            restriction of processing if the conditions of Art. 18 GDPR are
            fulfilled.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>6. Right to data portability (Art. 20 GDPR)</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            You have the right to receive your personal data concerning you
            provided to us, in a structured, commonly used and machine-readable
            format and have the right to transmit those data to another
            controller where the processing is based on consent and the data is
            processed by automated means.
          </Typography>

          <Typography variant="body1" component="div" gutterBottom>
            <strong>7. Right to object (Art. 21 GDPR)</strong>
          </Typography>
          <Typography component="p" gutterBottom>
            You have the right to object at any time to the creation of user
            profiles and to the processing of your personal data, provided that
            the processing is carried out based on Article 6 (1) lit. e, f GDPR.
            The controller shall no longer process the personal data unless the
            controller demonstrates compelling legitimate grounds for the
            processing which override the interests, rights and freedoms of the
            data subject or for the establishment, exercise or defense of legal
            claims. Where personal data are processed for direct marketing
            purposes, you shall have the right to object at any time to
            processing of your personal data concerning for such marketing.
          </Typography>
          <Typography variant="body1" component="div" gutterBottom>
            <strong>
              8. Right to lodge a complaint with a supervisory authority (Art.
              77 GDPR)
            </strong>
          </Typography>
          <Typography component="p" gutterBottom>
            Without prejudice to any other administrative or judicial remedy,
            every data subject shall have the right to lodge a complaint with a
            supervisory authority.
          </Typography>
        </Textblock>
      </Container>
    </Layout>
  )
}

export default withTrans(PrivacyPolicyPage)

export const Head = () => <Seo title={i18next.t("privacy_policy.title")} />
